import { Children, isValidElement } from 'react'

import { __prod__ } from '@/constants'

type Component = (
  ...args: any
) => JSX.Element | Array<(...args: any) => JSX.Element>

/**
 * Helper function for checking the children type
 * @param children React children
 * @param component Component to check against
 */
function childTypeChecker(
  children: React.ReactNode,
  component: Component | Component[]
) {
  const throwError = (displayName: any) => {
    let components = ''

    if (Array.isArray(component)) {
      component.forEach((comp) => {
        if (Array.isArray(comp)) {
          components = components + (components ? ', ' : '') + comp.name
        } else {
          components = components + (components ? ', ' : '') + comp.name
        }
      })
    } else {
      components = component.name
    }

    throw new Error(
      `Showcase should only have a children type of [${components}].\n--> received ${displayName}`
    )
  }

  Children.forEach(children, (child) => {
    if (isValidElement(child)) {
      // @ts-ignore display name does exist
      let displayName = child.type.displayName ?? child.type

      if (typeof displayName === 'symbol') {
        displayName = displayName.description
      }

      if (Array.isArray(component) && !__prod__) {
        if (!component.includes(child.type as Component))
          throwError(displayName)
      }

      if (!Array.isArray(component) && child.type !== component && !__prod__)
        throwError(displayName)
    }
  })
}

export default childTypeChecker
