/**
 * A helper function to get the post title and return a single string.
 * @param document Post title document
 * @returns post title
 */
function getPostTitle(document: KeystoneDocument[]): string {
  let postTitle = '';

  document.forEach((doc) => {
    doc.children.forEach((child) => {
      postTitle = postTitle + child.text;
    });
  });

  return postTitle;
}

export default getPostTitle;
