/**
 * generates post url
 * @param slug post slug
 * @returns absolute post url
 */
function getPostUrl(slug: string): string {
  return `${process.env.BASE_URL}/posts/${slug}`
}

export default getPostUrl
