/* eslint-disable jsx-a11y/alt-text */

import '@/styles/app.css'
import '@fontsource/nunito'
import '@fontsource/raleway'
import '@fontsource/lexend'
import '@fontsource/comfortaa'

import type { AppContext, AppProps } from 'next/app'

import { ApolloProvider } from '@apollo/client'
import App from 'next/app'
import ChacraIcon from '@/assets/images/chacra.png'
import { Chakra } from '@/components'
import Head from 'next/head'
import Script from 'next/script'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import client from '@/apollo/apollo-client'
import gsap from 'gsap'
import logger from 'logt'
import { useEffect } from 'react'

// import '@fontsource/montserrat/400.css'
// import '@fontsource/montserrat/300.css'
// import '@fontsource/montserrat/500.css'
// import '@fontsource/montserrat/600.css'
// import '@fontsource/montserrat/700.css'

// Register GSAP plugin here
gsap.registerPlugin(ScrollTrigger)

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const log = new logger('silly')
      log.image('silly', `${window.location.href}${ChacraIcon.src}`)
      console.log('Developed & Designed by Chacra Software with 💚')
    }
  }, [])

  return (
    <>
      <Script
        id="gtg"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		  })(window,document,'script','dataLayer','GTM-K2D2R77');`,
        }}
      />

      <ApolloProvider client={client}>
        <Head>
          <title>Chacra Software Solution</title>
          <meta
            name="description"
            content="American Engineering. Affordable Rates."
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1 viewport-fit=cover"
          />
          <meta
            property="og:image"
            content="https://res.cloudinary.com/hfq3eckim/image/upload/v1644397231/cms-demo/Projects%20Thumbnails/post-default_pmwqzi.png"
          />
        </Head>
        <Chakra cookies={pageProps.cookies}>
          <Component {...pageProps} />
        </Chakra>
      </ApolloProvider>
    </>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext)

  if (appContext.ctx.res?.statusCode === 404) {
    appContext.ctx.res.writeHead(302, { Location: '/' })
    appContext.ctx.res.end()
    return
  }

  return { ...appProps }
}

export default MyApp
