import { Box, Flex, Heading, Text } from '@chakra-ui/react'

export interface TestimonialProps {
  title: string
  message: string
  name: string
  occupation: string
}

const Testimonial = (props: TestimonialProps) => {
  return (
    <Flex alignItems="center" justify="center">
      <Box
        textAlign={{ base: 'center', md: 'center', lg: 'left' }}
        mt={{ base: '10', md: '10', lg: '20' }}
      >
        <Heading
          mb="8"
          color="#006400"
          fontWeight="bold"
          px={{ base: '10', md: '0' }}
          fontSize={{ base: '2xl', md: '2xl', lg: '5xl' }}
        >
          &quot;{props.title}&quot;
        </Heading>
        <Text
          px={{ base: '10', md: '0' }}
          fontSize={{ base: 'lg', md: '2xl', lg: '3xl' }}
          fontWeight="medium"
          mb="8"
          mr={{ base: 0, lg: '5' }}
        >
          &quot;{props.message}&quot;
        </Text>
        <Text
          fontSize={{ base: 'lg', md: '2xl', lg: '3xl' }}
          fontWeight="semibold"
        >
          {props.name}
        </Text>
        <Text
          fontSize={{ base: 'sm', md: 'xl', lg: 'lg' }}
          fontWeight="medium"
          mb={{ base: '10', md: '10', lg: '5' }}
        >
          {props.occupation}
        </Text>
      </Box>
    </Flex>
  )
}

export default Testimonial
