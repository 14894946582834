import { SystemStyleFunction, mode } from '@chakra-ui/theme-tools'

import { ComponentSingleStyleConfig } from '@chakra-ui/theme'

const variantOutline: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props
  if (c === 'green') {
    return {
      border: '2px solid',
      borderColor: 'green.500',
      color: mode('black', 'white')(props),
      _hover: {
        backgroundColor: 'green.500',
        color: 'white',
      },
      _active: {
        backgroundColor: mode('green.700', 'green.600')(props),
      },
    }
  }
  return {}
}

const Button: ComponentSingleStyleConfig = {
  baseStyle: {
    rounded: 'lg',
  },
  variants: {
    outline: variantOutline,
  },
  defaultProps: {
    variant: 'outline',
  },
}

export default Button
