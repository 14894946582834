import { Project } from '@/typings/project'
import create from 'zustand'

export interface ShowcaseStore {
  activeShowcase?: Project
  clearActiveShowcase: () => void
  setActiveShowcase: (project: Project) => void
}

const useShowcaseStore = create<ShowcaseStore>((set) => ({
  clearActiveShowcase: () => set({ activeShowcase: undefined }),
  setActiveShowcase: (project) => set({ activeShowcase: project }),
}))

export default useShowcaseStore
