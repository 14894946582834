import {
  Heading,
  HeadingProps,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import {
  DocumentRenderer as KeystoneDocumentRenderer,
  DocumentRendererProps as KeystoneDocumentRendererProps,
} from '@keystone-6/document-renderer'

const renderers: KeystoneDocumentRendererProps['renderers'] = {
  inline: {
    bold: ({ children }) => {
      return <strong>{children}</strong>
    },
  },
  block: {
    paragraph: ({ children, textAlign }) => {
      return (
        <Text
          textAlign={textAlign}
          mb="6"
          fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
        >
          {children}
        </Text>
      )
    },
    list: ({ type, children }) => {
      const items = children.map((item, index) => (
        <ListItem key={index} fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}>
          {item}
        </ListItem>
      ))
      if (type === 'ordered')
        return <OrderedList spacing={4}>{items}</OrderedList>

      return <UnorderedList spacing={4}>{items}</UnorderedList>
    },
    heading: ({ children, level, textAlign }) => {
      let size: HeadingProps['size'] = ''
      switch (level) {
        case 1:
          size = '3xl'
          break
        case 2:
          size = '2xl'
          break
        case 3:
          size = 'xl'
          break
        case 4:
          size = 'lg'
          break
        case 5:
          size = 'md'
          break
        case 6:
          size = 'sm'
          break
      }
      return (
        <Heading as={`h${level}`} textAlign={textAlign} my="6" size={size}>
          {children}
        </Heading>
      )
    },
  },
}

export interface DocumentRendererProps {
  document: KeystoneDocumentRendererProps['document']
}

const DocumentRenderer = (props: DocumentRendererProps) => {
  return (
    <KeystoneDocumentRenderer document={props.document} renderers={renderers} />
  )
}

export default DocumentRenderer
