import { MotionFlex, useShowcaseStore } from '..'

import BlurImg from '@/assets/images/project-img-blur.png'
import { FlexProps } from '@chakra-ui/react'
import Image from 'next/image'
import { Project } from '@/typings/project'
import { Variants } from 'framer-motion'
import useLongPress from '@/hooks/useLongPress'

export type ShowcaseItemProps = Omit<FlexProps, 'id'> &
  Project & {
    custom?: {
      scale?: number
      x?: string | number
      y?: string | number
    }
  }

const ShowcaseItem = ({
  id,
  name,
  description,
  thumbnail,
  url,
  galleries,
  tags,
  bgColor,
  color,
  descriptionColor,
  tagsDefaultColor,
  ...props
}: ShowcaseItemProps) => {
  const setActiveShowcase = useShowcaseStore((state) => state.setActiveShowcase)

  const gestures = useLongPress(
    () =>
      setActiveShowcase({
        id,
        name,
        description,
        thumbnail,
        url,
        galleries,
        tags,
        bgColor,
        color,
        descriptionColor,
        tagsDefaultColor,
      }),
    { threshold: 1000 }
  )

  const item: Variants = {
    exit: {
      scale: 0,
    },
    animate: (custom: ShowcaseItemProps['custom']) => ({
      scale: custom?.scale,
      x: custom?.x,
      y: custom?.y,
      transition: {
        duration: 0.5,
      },
    }),
    whileTap: {
      zIndex: 99,
      scale: 1.2,
      transition: {
        duration: 1,
      },
    },
  }

  return (
    //  @ts-ignore chakra overrides type
    <MotionFlex
      bgColor="red.200"
      w="330px"
      h="180px"
      rounded="xl"
      justify="center"
      align="center"
      position="relative"
      overflow="hidden"
      cursor="pointer"
      m={{ base: '0 calc(7vw - 165px)', xl: '-34px' }}
      variants={item}
      initial="exit"
      animate="animate"
      exit="exit"
      whileTap="whileTap"
      bg="black"
      border="1px"
      borderColor="primary"
      {...props}
      {...gestures}
    >
      <Image
        src={thumbnail}
        layout="fill"
        objectFit="cover"
        alt="project-thumb"
        placeholder="blur"
        blurDataURL={BlurImg.src}
      />
    </MotionFlex>
  )
}

export default ShowcaseItem
