import { Box, BoxProps, forwardRef } from '@chakra-ui/react'

import { motion } from 'framer-motion'

export type FadeUpMaskProps = BoxProps

const FadeUpMask = forwardRef<FadeUpMaskProps, 'div'>(
  ({ children, ...props }, ref) => {
    return (
      <Box ref={ref} overflow="hidden" {...props}>
        <motion.div
          initial={{ y: '100%', opacity: 0 }}
          whileInView={{ y: '0%', opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          {children}
        </motion.div>
      </Box>
    )
  }
)

export default FadeUpMask
