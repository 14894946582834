import { Styles } from '@chakra-ui/theme-tools'

// all global style overrides
const styles: Styles = {
  global: {
    a: {
      color: 'primary',
      _hover: {
        color: 'darkgreen',
      },
      transitionProperty: 'color',
      transitionDuration: '.3s',
    },
  },
}

export default styles
