import { PartsStyleFunction, getColor, mode } from '@chakra-ui/theme-tools'

import { inputAnatomy as parts } from '@chakra-ui/anatomy'

function getDefaults(props: Record<string, any>) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props
  return {
    focusBorderColor: fc || mode('blue.500', 'blue.300')(props),
    errorBorderColor: ec || mode('red.500', 'red.300')(props),
  }
}

const variantUnderline: PartsStyleFunction<typeof parts> = (props) => {
  const { colorScheme: c, theme } = props
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props)

  return {
    field: {
      backgroundColor: 'unset',
      borderWidth: 2,
      borderColor: mode('black', 'white')(props),
      borderTop: 0,
      borderX: 0,
      color: mode('black', 'white')(props),
      px: 0,
      rounded: 0,
      _placeholder: { color: mode('black', 'white')(props) },
      _focus: {
        borderColor: c === 'green' ? 'green.500' : getColor(theme, fc),
      },
      _invalid: {
        borderColor: getColor(theme, ec),
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
    },
  }
}

const Input = {
  variants: {
    underline: variantUnderline,
  },
}

export default Input
