import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  Flex,
  FlexProps,
  Heading,
  HeadingProps,
  Text,
  TextProps,
  Wrap,
  WrapItem,
  WrapItemProps,
  WrapProps,
} from '@chakra-ui/react'

import { motion } from 'framer-motion'

export const MotionBox = motion<BoxProps>(Box)
export const MotionContainer = motion<ContainerProps>(Container)
export const MotionFlex = motion<FlexProps>(Flex)
export const MotionHeading = motion<HeadingProps>(Heading)
export const MotionText = motion<TextProps>(Text)
export const MotionWrap = motion<WrapProps>(Wrap)
export const MotionWrapItem = motion<WrapItemProps>(WrapItem)
