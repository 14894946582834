import { AnimateSharedLayout, Variants } from 'framer-motion'
import { Children, ReactNode, cloneElement, isValidElement } from 'react'
import { MotionFlex, ShowcaseItem } from '..'

import { ActiveShowcaseItem } from '.'
import { ShowcaseItemProps } from './ShowcaseItem'
import childTypeChecker from '@/helpers/childTypeChecker'
import interpolate from '@/utils/interpolate'

export interface ShowcaseProps {
  children?: ReactNode
}

const Showcase = (props: ShowcaseProps) => {
  childTypeChecker(props.children, [ShowcaseItem, ActiveShowcaseItem])

  // Number of children
  const childCount = Children.count(props.children)

  // Middle index of the children array
  const middleItem = Math.floor(childCount / 2)

  const showcaseItems = Children.map(props.children, (child, index) => {
    if (!isValidElement<ShowcaseItemProps>(child)) return

    if (middleItem === index) {
      return cloneElement(child, {
        zIndex: childCount,
        custom: {
          scale: 1,
          y: 0,
          x: 0,
        },
      })
    }
    // determine if the item is on the left side from the middle
    const isLeftItemFromMiddle = middleItem > index

    // layer order from middle we will use this to set the z-index of the item
    const layerOrder = isLeftItemFromMiddle
      ? childCount - middleItem + index
      : -index + childCount + middleItem

    // y axis offset value increment by -35
    const offsetY = isLeftItemFromMiddle
      ? (middleItem - index) * 35
      : (index - middleItem) * 35

    // x axis offset interpolated value base from the order
    const offsetX = interpolate({
      inputRange: [middleItem + 1, childCount + layerOrder],
      outputRange: [0, Math.abs((middleItem - index) * 100)],
      value: layerOrder,
    })

    // item scale interpolated value base from the order
    const scale = interpolate({
      inputRange: [1, childCount],
      outputRange: [0.48, 1],
      value: layerOrder,
    })

    return cloneElement(child, {
      zIndex: layerOrder,
      custom: {
        scale,
        y: `${-offsetY}px`,
        x: `${isLeftItemFromMiddle ? -offsetX : Math.abs(offsetX)}px`,
      },
    })
  })

  const container: Variants = {
    animate: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
  }

  return (
    <MotionFlex
      key="showcase-box"
      w="full"
      align="center"
      justify="center"
      variants={container}
      initial="exit"
      animate="animate"
      exit="exit"
      mt={40}
    >
      <AnimateSharedLayout>{showcaseItems}</AnimateSharedLayout>
    </MotionFlex>
  )
}

export default Showcase
