import BlurImg from '@/assets/images/project-img-blur.png'
import { FlexProps } from '@chakra-ui/react'
import Image from 'next/image'
import { MotionFlex } from '..'
import { ProjectGallery } from '@/typings/project'
import { Variants } from 'framer-motion'

export type ActiveShowcaseItemProps = Omit<FlexProps, 'id'> &
  ProjectGallery & {
    custom?: {
      scale?: number
      x?: string | number
      y?: string | number
    }
  }

const ActiveShowcaseItem = ({ src, ...props }: ActiveShowcaseItemProps) => {
  const item: Variants = {
    exit: {
      scale: 0,
    },
    animate: (custom: ActiveShowcaseItemProps['custom']) => ({
      scale: custom?.scale,
      x: custom?.x,
      y: custom?.y,
      transition: {
        duration: 0.5,
      },
    }),
    whileHover: {
      zIndex: 99,
      scale: 1.2,
      transition: {
        duration: 1,
      },
    },
  }

  return (
    //  @ts-ignore chakra overrides type
    <MotionFlex
      bgColor="black"
      w="330px"
      h="180px"
      rounded="xl"
      justify="center"
      align="center"
      position="relative"
      overflow="hidden"
      cursor="pointer"
      m={{ base: '0 calc(7vw - 165px)', xl: '-34px' }}
      variants={item}
      whileHover="whileHover"
      initial="exit"
      animate="animate"
      border="1px"
      exit="exit"
      {...props}
    >
      <Image
        src={src}
        layout="fill"
        objectFit="cover"
        alt="project-thumb"
        placeholder="blur"
        blurDataURL={BlurImg.src}
      />
    </MotionFlex>
  )
}

export default ActiveShowcaseItem
