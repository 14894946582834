export interface Interpolate {
  /**
   * The input range
   */
  inputRange: InterpolateRange;
  /**
   * The output range
   */
  outputRange: InterpolateRange;
  /**
   * The current value
   */
  value: number;
}

export type InterpolateRange = [number, number];
/**
 * Utility function to interpolate against ranges.
 * @param param0 IInterpolate
 * @returns interpolated value
 */
function interpolate({ inputRange, outputRange, value }: Interpolate): number {
  const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;

  const inverseLerp = (x: number, y: number, a: number) =>
    clamp((a - x) / (y - x));

  const clamp = (a: number, min = 0, max = 1) =>
    Math.min(max, Math.max(min, a));

  const range = (x1: number, y1: number, x2: number, y2: number, a: number) =>
    lerp(x2, y2, inverseLerp(x1, y1, a));

  return range(
    inputRange[0],
    inputRange[1],
    outputRange[0],
    outputRange[1],
    value
  );
}

export default interpolate;
