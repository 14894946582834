import { Box, Flex, Text } from '@chakra-ui/react'

import PostTitle from './PostTitle'
import { Posts_posts } from '@/apollo/__generated__/Posts'
import dayjs from 'dayjs'

export interface PostCardProps {
  /**
   * Post title
   */
  postTitle?: Posts_posts['postTitle']
  /**
   * Post highlight color
   */
  highlightColor: string
  /**
   * Post outline color
   */
  outlineColor: string
  /**
   * Post slug to be used in url
   */
  slug: string
  /**
   * Blog tag
   */
  tag: string
  /**
   * Blog creation date
   */
  publishDate: Date
  /**
   * Blog views counter
   */
  views?: number
}

/**
 * Blog card component
 */
const PostCard = ({
  postTitle,
  tag,
  publishDate,
  views,
  highlightColor,
  outlineColor,
}: PostCardProps) => {
  return (
    <Flex
      rounded="md"
      flexDirection="column"
      minHeight="310px"
      boxShadow="md"
      bg="white"
      py={4}
      alignItems="flex-start"
    >
      <Text
        as="span"
        borderBottomWidth="3px"
        borderColor="orange.400"
        fontSize="lg"
        color="gray.600"
        fontWeight="semibold"
        mb={4}
        ml={4}
      >
        {tag}
      </Text>
      <Box
        flex={1}
        borderBottom="1px"
        borderColor="gray"
        mb="2"
        pb={4}
        w="full"
      >
        <PostTitle
          pt={4}
          px={4}
          postTitle={postTitle}
          highlightColor={highlightColor}
          outlineColor={outlineColor}
        />
      </Box>
      <Flex width="full" justifyContent="space-between" px={4}>
        <Text color="gray.600" fontWeight="semibold">
          {dayjs(publishDate).format('MMMM D YYYY')}
        </Text>
        <Text color="gray.600" fontWeight="semibold">
          {views} views
        </Text>
      </Flex>
    </Flex>
  )
}

export default PostCard
