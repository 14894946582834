import { Text } from '@chakra-ui/react'
import flatten from 'lodash/flatten'

export interface PostTitleHighlighterProps {
  postTitle: KeystoneDocument[]
  highlightColor?: string
  outlineColor?: string
}

const PostTitleHighlighter = (props: PostTitleHighlighterProps) => {
  const highlightedWords = flatten(
    props.postTitle.map((title) => {
      if (title.type !== 'paragraph') return

      const words = title.children.map((word, index) => {
        if (!word.bold && !word.italic) return word.text

        return (
          <Text
            key={`${index}-${word.text}`}
            as="span"
            position="relative"
            display="inline-block"
            zIndex={1}
            mx={2}
            mb={2}
            _after={{
              position: 'absolute',
              top: 0,
              left: '50%',
              transform: 'translate(-50%, 0)',
              content: '" "',
              width: '110%',
              height: '100%',
              bg: word.bold ? props.highlightColor : 'unset',
              border: word.italic ? '2px' : 'unset',
              borderColor: props.outlineColor,
              zIndex: -1,
              rounded: 'full',
            }}
          >
            {word.text}
          </Text>
        )
      })

      return words
    })
  )

  return <>{highlightedWords}</>
}

export default PostTitleHighlighter
