import { Heading, HeadingProps, forwardRef } from '@chakra-ui/react'

import { PostTitleHighlighter } from '..'
import { PostTitleHighlighterProps } from './PostTitleHighlighter'
import { Posts_posts } from '@/apollo/__generated__/Posts'

export type PostTitleProps = Omit<HeadingProps, 'postTitle'> &
  Omit<PostTitleHighlighterProps, 'postTitle'> & {
    postTitle?: Posts_posts['postTitle']
  }

const PostTitle = forwardRef<PostTitleProps, 'h2'>(
  ({ postTitle, highlightColor, outlineColor, ...props }, ref) => {
    return (
      <Heading
        ref={ref}
        textAlign="left"
        fontSize={{ base: '3xl', md: '3xl', lg: '4xl' }}
        noOfLines={3}
        lineHeight="50px"
        color="gray.800"
        // style={{ wordSpacing: '10px' }}
        {...props}
      >
        <PostTitleHighlighter
          postTitle={postTitle?.document ?? []}
          highlightColor={highlightColor}
          outlineColor={outlineColor}
        />
      </Heading>
    )
  }
)

export default PostTitle
