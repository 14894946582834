const colors = {
  primary: '#1BA857',
  green: {
    50: '#F3FFF8',
    100: '#b9f5dd',
    200: '#8fedc4',
    300: '#65e6a9',
    400: '#3cdf8c',
    500: '#1BA857',
    600: '#199a50',
    700: '#0e6e3f',
    800: '#034329',
    900: '#00180a',
  },
}

export default colors
