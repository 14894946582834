import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'

import PostView from '../PostView'
import { Posts_posts } from '@/apollo/__generated__/Posts'

export interface PostModalProps {
  post: Posts_posts
  children?: React.ReactNode
}

const PostModal = (props: PostModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box id="blog-post-learn-btn" cursor="pointer" onClick={() => onOpen()}>
      {props.children}
      <Modal onClose={onClose} size="full" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent bg="transparent" mt="14" minHeight="calc(100vh - 56px)">
          <ModalCloseButton top="-44px" />
          <ModalBody bg="white" pt="6" borderTopRadius="2xl">
            <PostView post={props.post} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default PostModal
