import {
  Box,
  Container,
  Flex,
  HStack,
  IconButton,
  Text,
} from '@chakra-ui/react'
import { FacebookShareButton, WhatsappShareButton } from 'next-share'
import {
  IncrementViews,
  IncrementViewsVariables,
} from '@/apollo/__generated__/IncrementViews'
import { RiFacebookFill, RiWhatsappFill } from 'react-icons/ri'
import { useCallback, useEffect } from 'react'

import { DocumentRenderer } from '..'
import { INCREMENT_VIEWS } from '@/apollo/mutation'
import { PostStaticProps } from '@/pages/posts/[slug]'
import PostTitle from '../PostCard/PostTitle'
import dayjs from 'dayjs'
import getPostTitle from '@/helpers/getPostTitle'
import getPostUrl from '@/helpers/getPostUrl'
import { useMutation } from '@apollo/client'

export type PostViewProps = PostStaticProps

const PostView = ({ post }: PostViewProps) => {
  const [addPostView] = useMutation<IncrementViews, IncrementViewsVariables>(
    INCREMENT_VIEWS
  )

  const mutate = useCallback(async () => {
    if (post)
      await addPostView({
        variables: { incrementViewsInput: { slug: post.slug } },
      })
  }, [addPostView, post])

  useEffect(() => {
    mutate()
  }, [mutate])

  if (!post) return <Text>Cannot retrieve post data</Text>

  const postTitle = post.postTitle ? getPostTitle(post.postTitle.document) : ''

  const postUrl = getPostUrl(post.slug)

  return (
    <Container as="article" maxW="container.xl" h="full" pb={12}>
      <Box as="header" pb="2" mb="10" borderBottom="2px" borderColor="gray.100">
        <PostTitle
          p={8}
          ml={-8}
          postTitle={post.postTitle}
          outlineColor={post.outlineColor ?? ''}
          highlightColor={post.highlightColor ?? ''}
          noOfLines={0}
        />
        <Flex
          w="full"
          justify="space-between"
          align="center"
          flexWrap="wrap"
          gap={{ base: 4, md: 0 }}
        >
          <Text color="gray.600" fontWeight="semibold">
            {dayjs(post.publishDate).format('MMMM D YYYY')}
          </Text>
          <Text color="gray.600" fontWeight="semibold">
            {post.views} views
          </Text>
          <Flex alignItems="center">
            <Text mr="2" color="gray.600" fontWeight="semibold">
              Share on
            </Text>
            <HStack>
              <WhatsappShareButton url={postUrl ?? ''} title={postTitle}>
                <IconButton
                  as="div"
                  aria-label=""
                  colorScheme="whatsapp"
                  fontSize="20px"
                  icon={<RiWhatsappFill />}
                />
              </WhatsappShareButton>
              <FacebookShareButton url={postUrl ?? ''} title={postTitle}>
                <IconButton
                  as="div"
                  aria-label=""
                  colorScheme="facebook"
                  fontSize="20px"
                  icon={<RiFacebookFill />}
                />
              </FacebookShareButton>
            </HStack>
          </Flex>
        </Flex>
      </Box>
      <Box textAlign="unset">
        <DocumentRenderer document={post.content?.document} />
      </Box>
    </Container>
  )
}

export default PostView
